.cs-message--incoming .cs-message__content,
.cs-message--outgoing .cs-message__content {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 1rem;
}
.cs-message--incoming .cs-message__content {
  background: orange;
}

.cs-conversation-header,
.cs-conversation-header__content .cs-conversation-header__user-name,
.cs-conversation-header__content .cs-conversation-header__info,
.cs-chat-container .cs-message-input {
  font-size: 1.2rem;
  background: black;
  color: white;
}
.cs-conversation-header__avatar {
  width: 3.4rem;
  height: 3.4rem;
}
.cs-conversation-header__avatar .cs-avatar.cs-avatar--md {
  width: 100%;
  height: 100%;
}

.cs-message-list {
  margin-top: 1rem;
}

.cs-main-container {
  border: none;
  height: auto;
  flex-grow: 1;
}

.cs-chat-container,
.cs-message-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(20, 15, 20);
  background: -moz-linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140f14",endColorstr="#2d393d",GradientType=1);
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container {
  background-color: #1c1d23 !important;
  color: white;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  color: orange;
}

.cs-typing-indicator__dot {
  background-color: #eb6c09;
}
.cs-message-list .cs-typing-indicator {
  background-color: transparent;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  box-sizing: border-box;
  content: attr(data-placeholder);
  color: rgba(255, 255, 255, 0.38);
  display: block;
  cursor: text;
}
