@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #000000;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(20, 15, 20);
  background: -moz-linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(20, 15, 20, 1) 3%,
    rgba(28, 29, 35, 1) 50%,
    rgba(38, 44, 49, 1) 78%,
    rgba(45, 57, 61, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140f14",endColorstr="#2d393d",GradientType=1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
